import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Container from "../components/container"
import Seo from "../components/seo"
import Slider from "../components/slider"

const IndexPage = ({
  path,
  data: {
    home: { keyvisualSlider },
  },
}) => {

  return (
    <Layout path={path} isHomePage>
      <Seo title="" />
      <Container fullWidth maxWidth5xl>
        <Slider items={keyvisualSlider} />
      </Container>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query HomePage {
    site {
      siteMetadata {
        siteUrl
      }
    }
    home: contentfulHome(id: { eq: "987b34f9-1535-50d9-b520-153ea133869f" }) {
      keyvisualSlider {
        titleLeft
        titleRight
        image {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: DOMINANT_COLOR
            quality: 80
          )
        }
      }
    }
  }
`
